import React from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom'
import { RedocStandalone } from 'redoc'

import apiDoc from './file/openapi.yaml'
import payToApiDoc from './file/openapiPayTo.yaml'
import ccApiDoc from './file/openapiCC.yaml'

const routes = [
  { path: '/payments', doc: apiDoc, title: 'Monoova Payments API' },
  { path: '/payTo', doc: payToApiDoc, title: 'Monoova PayTo API' },
  { path: '/cards', doc: ccApiDoc, title: 'Monoova Card Payments API' }
]

function App() {
  return (
    <div className="App">
      <Router>
        <div className="linksBox">
          <div className="links">
            {routes.map((route) => (
              <NavLink key={route.path} to={route.path} className="link">
                {route.title}
              </NavLink>
            ))}
          </div>
          <div className="tabs">
            <Routes>
              <Route path="/" element={<Navigate to="/payments" replace />} />
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <RedocStandalone
                      specUrl={route.doc}
                      options={{
                        theme: {
                          logo: {
                            gutter: '20px'
                          },
                          typography: {
                            optimizeSpeed: true,
                            smoothing: 'antialiased',
                            code: {
                              color: '#6639B7'
                            },
                            links: {
                              color: '#6639B7',
                              visited: '#2f1b5a',
                              hover: '#8E6FE4',
                              textDecoration: 'underline'
                            }
                          },
                          sidebar: {
                            textColor: '#000000',
                            backgroundColor: '#ffffff',
                            activeTextColor: '#6639B7',
                            activeBgColor: 'rgba(102, 57, 183, 0.1)'
                          },
                          rightPanel: {
                            backgroundColor: '#20123f',
                            textColor: '#ffffff'
                          },
                          schema: {
                            requireLabelColor: '#8E6FE4',
                            typeNameColor: '#6639B7',
                            typeTitleColor: '#2f1b5a',
                            nestedBackground: '#f8f8f8'
                          }
                        },
                        colors: {
                          primary: {
                            main: '#6639B7',
                            light: 'rgba(102, 57, 183, 0.42)'
                          }
                        }
                      }}
                    />
                  }
                />
              ))}
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  )
}

export default App
